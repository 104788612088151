import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import { Router } from '@angular/router';
import { BookService } from '../../services/book-service';

@Injectable({ providedIn: 'root' })
export class BookCompletePageGuard {
  constructor(
    private bookService: BookService,
    private router: Router,
  ) {}

  async canActivate(): Promise<void> {
    this.bookService.bookComplete$.pipe(take(1)).subscribe(bookComplete => {
      if (!bookComplete) {
        void this.router.navigate(['']);
      }

      return true;
    });
  }
}
