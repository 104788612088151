import { PreferencesService } from '../services/preferences.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { STORAGE_KEY_SEARCH } from './constants';

export interface Search {
  history: Array<string>;
}

@Injectable({ providedIn: 'root' })
export class SearchState {
  constructor(private preferenceService: PreferencesService) {}

  private readonly STORAGE_KEY = STORAGE_KEY_SEARCH;

  readonly searchInitialValue = {
    history: [],
  };

  search$!: BehaviorSubject<Search>;

  async initialize(): Promise<void> {
    const storedSearch = await this.get();

    if (storedSearch) {
      this.search$ = new BehaviorSubject<Search>(storedSearch);
    } else {
      void this.set(this.searchInitialValue);
    }

    return;
  }

  private async get(): Promise<Search | null> {
    return JSON.parse(<string>(await this.preferenceService.get(this.STORAGE_KEY)).value);
  }

  async set(settings: Partial<Search>): Promise<void> {
    const updatedOptions: Search = {
      ...this.search$?.value,
      ...settings,
    };

    if (this.search$) {
      this.search$.next(updatedOptions);
    } else {
      this.search$ = new BehaviorSubject<Search>(updatedOptions);
    }

    await this.preferenceService.set(this.STORAGE_KEY, JSON.stringify(updatedOptions));
  }
}
