import { Injectable, NgZone } from '@angular/core';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { UserProvider } from '../providers/user.provider';
import { firstValueFrom } from 'rxjs';
import { UserService } from './user.service';
import { ProfileState } from '../store/profile.state';
import { ProfileService } from './profile.service';
import { UserLocationState } from '../store/user-location.state';
import { LanguageState } from '../store/language.state';
import { DefaultProjectState } from '../store/default-project.state';
import { Gender } from '../pages/profile/profile.model';
import { ProfileProvider } from '../providers/profile.provider';
import { BookService } from './book-service';
import { BooksProvider } from '../providers/books.provider';

interface CustomAUTWindow {
  cypressNavigateByUrl: (url: string) => void;
  programmaticBookComplete: (uuid: string) => void;
  programmaticLogin: (email: string, password: string) => Promise<void>;
  programmaticCreateAccountAndProfile: (email: string, profileName: string) => Promise<void>;
}

@Injectable({ providedIn: 'root' })
export class DeveloperToolsService {
  constructor(
    private router: Router,
    private ngZone: NgZone,
    private userProvider: UserProvider,
    private userService: UserService,
    private profileState: ProfileState,
    private profileService: ProfileService,
    private userLocationState: UserLocationState,
    private languageState: LanguageState,
    private projectState: DefaultProjectState,
    private profileProvider: ProfileProvider,
    private bookService: BookService,
    private booksProvider: BooksProvider,
  ) {}

  initialize(): void {
    if (environment.envName === 'prod') {
      console.error('Developer tools are not available with production environment');
      return;
    }

    (window as unknown as CustomAUTWindow).cypressNavigateByUrl = (url: string): void => this.cypressNavigateByUrl(url);
    (window as unknown as CustomAUTWindow).programmaticLogin = (email: string, password: string): Promise<void> =>
      this.programmaticLogin(email, password);
    (window as unknown as CustomAUTWindow).programmaticCreateAccountAndProfile = (email: string, password: string): Promise<void> =>
      this.programmaticCreateAccountAndProfile(email, password);
  }

  cypressNavigateByUrl(url: string): void {
    this.ngZone.run(() => {
      void this.router.navigateByUrl(url);
    });
  }

  async programmaticLogin(email: string, password: string): Promise<void> {
    await this.ngZone.run(async () => {
      const user = await firstValueFrom(this.userProvider.loginWithEmail(email, password));
      await this.userService.login(user);

      await this.profileService.setProfile(this.profileState.profiles$.value[0]);
    });
  }

  async programmaticCreateAccountAndProfile(email: string, profileName: string): Promise<void> {
    await this.ngZone.run(async () => {
      const user = await firstValueFrom(
        this.userProvider.createAccount({
          email,
          password: 'P4ssword!',
          acceptedTerms: true,
          acceptedCoppa: true,
          country: this.userLocationState.userLocation$.value.countryCode,
          language: this.languageState.language$.value.selected,
          projectCode: this.projectState.defaultProject$.value.code,
        }),
      );

      await this.userService.login(user);

      const profile = await firstValueFrom(
        this.profileProvider.createUserProfile({
          name: profileName,
          avatar: 'avatar_1.svg',
          gender: Gender.Female,
          minAge: 3,
          maxAge: 5,
          weeklyReadingGoalBooks: null,
        }),
      );

      await this.profileService.addAndSetProfile(profile);
    });
  }
}
