import { ChangeDetectionStrategy, Component } from '@angular/core';
import { addIcons } from 'ionicons';
import { globeOutline, personOutline } from 'ionicons/icons';
import { ShelfType } from '../../services/book-shelf.service';
import { fadeInOut } from '../../utils/animations';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { AnalyticsEventType, PageViewEvents } from '../../services/analytics/analytics.model';
import { HeaderComponent } from '../../components/header/header.component';
import { SearchComponent } from './component/search/search.component';
import { BookShelfComponent } from '../../components/book-shelf/book-shelf.component';
import { BookCategoriesShelvesComponent } from '../../components/book-categories-shelves/book-categories-shelves.component';
import { IonContent } from '@ionic/angular/standalone';
import { AsyncPipe } from '@angular/common';
import { ProfileState } from '../../store/profile.state';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
  animations: [fadeInOut],
  standalone: true,
  imports: [HeaderComponent, SearchComponent, BookShelfComponent, BookCategoriesShelvesComponent, IonContent, AsyncPipe],
})
export class HomePage {
  constructor(
    public profileState: ProfileState,
    private analyticsService: AnalyticsService,
  ) {
    addIcons({ globeOutline, personOutline });
  }

  isSearchFocused = false;

  protected readonly shelfType = ShelfType;

  ionViewDidEnter(): void {
    this.analyticsService.sendEvent(AnalyticsEventType.InScreen, { screenName: PageViewEvents.Home });
  }

  searchFocused(focused: boolean): void {
    this.isSearchFocused = focused;
  }
}
