<ion-app appIsMobile>
  @if (appState.appReady$ | async) {
    @if ((appService.isMobile$ | async) === false) {
      <ion-menu contentId="main-content" type="overlay" [swipeGesture]="false">
        <app-menu></app-menu>
      </ion-menu>
    }

    <ion-router-outlet id="main-content"></ion-router-outlet>
  }
  <app-splash-screen> </app-splash-screen>
</ion-app>
