import { Injectable } from '@angular/core';
import { filter, switchMap, take } from 'rxjs';
import { AppState } from '../../store/app.state';
import { UserState, UserType } from '../../store/user.state';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class DeleteAccountPageGuard {
  constructor(
    private appState: AppState,
    private userState: UserState,
    private router: Router,
  ) {}

  // This guard don't check that the user is logged in after the component has been loaded (because of the take(1))
  // Used in the account-deletion page
  async canActivate(): Promise<void> {
    this.appState.appReady$
      .pipe(
        filter(appReady => appReady),
        switchMap(() => this.userState.user$),
        take(1),
      )
      .subscribe(user => {
        if (user.userType !== UserType.USER) {
          void this.router.navigate(['home']);
        }
      });
  }
}
