<div class="create-account-login-container" data-cy="authentication-desktop-modal">
  <div class="close-modal" (click)="closeModal(false)">
    <div class="close-btn"></div>
  </div>
  @if ((authenticationStepService.currentStep$ | async)?.title) {
    <div class="title" [class.centered]="shouldCenterTitle((authenticationStepService.currentStep$ | async)?.step)">
      @if ((authenticationStepService.currentStep$ | async)?.showBack) {
        <div class="previous" (click)="navigateBack()"></div>
      }
      <div>{{ (authenticationStepService.currentStep$ | async)?.title }}</div>
    </div>
  }

  <app-authentication [confirmEmailToken]="confirmEmailToken" [resetPasswordData]="resetPasswordData" (closePage)="closeModal()"></app-authentication>
</div>
