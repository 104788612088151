import { Injectable } from '@angular/core';
import { combineLatest, filter, switchMap, take } from 'rxjs';
import { AppState } from '../../store/app.state';
import { Router } from '@angular/router';
import { ProfileState } from '../../store/profile.state';
import { UserState } from '../../store/user.state';
import { FlagsState } from '../../store/flags.state';

@Injectable({ providedIn: 'root' })
export class ProfileSelectGuard {
  constructor(
    private appState: AppState,
    private profileState: ProfileState,
    private userState: UserState,
    private flagsState: FlagsState,
    private router: Router,
  ) {}

  async canActivate(): Promise<void> {
    this.appState.appReady$
      .pipe(
        filter(appReady => appReady),
        switchMap(() => combineLatest([this.profileState.profiles$, this.userState.isMoya$, this.flagsState.flags$])),
        take(1),
      )
      .subscribe(([profiles, isMoya, flags]) => {
        // Moya user first access, we redirect to the profile creation page & display the moya onboarding modal
        if ((isMoya && profiles.length < 2 && !flags.moyaProfileOnboardingCompleted) || profiles.length < 2) {
          //   void this.router.navigate(['/profile/create', { state: { hideHeader: true } }]);
        }
      });
  }
}
