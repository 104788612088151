import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { ToastService } from '../services/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private toastService: ToastService,
    private translateService: TranslateService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(error => {
        // We only do this for API's that contains the booksmart token
        if (error instanceof HttpErrorResponse && error.status !== 401 && error.status !== 403 && error.status !== 404) {
          // TODO Reactivate this toast after fixing the issue with 400 errors B20CP-602
          // void this.toastService.present({
          //   message: this.translateService.instant('PWA_appIssues_error_serverError'),
          //   type: ToastType.Negative,
          //   displayClose: false,
          // });
        }

        return throwError(() => error);
      }),
    );
  }
}
