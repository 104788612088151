import { Injectable } from '@angular/core';
import { filter, switchMap, take } from 'rxjs';
import { AppState } from '../store/app.state';
import { Router } from '@angular/router';
import { UserState, UserType } from '../store/user.state';

@Injectable({ providedIn: 'root' })
export class GuestGuard {
  constructor(
    private appState: AppState,
    private router: Router,
    private userState: UserState,
  ) {}

  async canActivate(): Promise<void> {
    this.appState.appReady$
      .pipe(
        filter(appReady => appReady),
        switchMap(() => this.userState.user$),
        take(1),
      )
      .subscribe(user => {
        if (user.userType === UserType.GUEST) {
          void this.router.navigate(['home']);
          return false;
        }

        return true;
      });
  }
}
