import { AppState } from '../store/app.state';
import { ActivatedRoute, Router } from '@angular/router';
import { BookService } from '../services/book-service';
import { UserState, UserType } from '../store/user.state';
import { ProfileState } from '../store/profile.state';
import { Injectable } from '@angular/core';
import { combineLatest, filter, switchMap, take } from 'rxjs';
import { FlagsState } from '../store/flags.state';
import { ModalController } from '@ionic/angular/standalone';
import { LanguageSelectionModalPage } from '../modals/language-selection/language-selection-modal.page';

@Injectable({ providedIn: 'root' })
export class CoreGuard {
  constructor(
    private appState: AppState,
    private router: Router,
    private bookService: BookService,
    private userState: UserState,
    private profileState: ProfileState,
    private flagsState: FlagsState,
    private modalController: ModalController,
    private route: ActivatedRoute,
  ) {}

  private redirectedToProfileSelection = false;
  private languageSelectionModalDisplayed = false;

  async canActivate(): Promise<void> {
    this.appState.appReady$
      .pipe(
        filter(appReady => appReady),
        take(1),
        switchMap(() => combineLatest([this.profileState.profiles$, this.userState.user$])),
      )
      .subscribe(async ([profiles, user]) => {
        // If the current route is not a book route, we need to clear the book saved in the memory
        if (this.router.url.split('/')[1] !== 'book') {
          this.bookService.clear();
        }

        // If the user is logged in, we must redirect to the profile selection page except for email confirmation modal
        if (user.userType !== UserType.GUEST && !this.redirectedToProfileSelection && this.routeShouldRedirecToProfile()) {
          if (profiles.length > 1) {
            void this.router.navigate(['profile/select'], {
              state: {
                hideHeader: true,
                firstOpening: true,
                redirectTo: this.router.url,
              },
            });
          } else {
            void this.router.navigate(['/profile/create'], { state: { hideHeader: true } });
          }

          this.redirectedToProfileSelection = true;

          this.showOptionalLanguageSelectionModal();

          return false;
        }

        this.redirectedToProfileSelection = true;

        this.showOptionalLanguageSelectionModal();

        return true;
      });
  }

  private showOptionalLanguageSelectionModal(): void {
    if (this.languageSelectionModalDisplayed) {
      return;
    }

    this.languageSelectionModalDisplayed = true;
    // We display this modal only the first time the user access the app & if the device language does not match
    // With one of the language available in the app.
    if (!this.flagsState.flags$.value.languageSelectionModalPrompted) {
      void this.modalController
        .create({
          canDismiss: (_, role?: string) => new Promise<boolean>(resolve => resolve(role === 'close')),
          component: LanguageSelectionModalPage,
          animated: false,
          cssClass: 'wr-modal language-selection fullscreen',
        })
        .then(l => l.present());

      this.redirectedToProfileSelection = true;
    }
  }

  private routeShouldRedirecToProfile(): boolean {
    if (this.router.url.startsWith('/confirmation-email') && this.matchJwt(this.route.snapshot.queryParams['jwt'])) {
      return false;
    } else if (this.router.url.startsWith('/reset-password')) {
      return false;
    }

    return true;
  }

  private matchJwt(urlJwt: string, storedUserId = this.userState.user$.value.id): boolean {
    try {
      const urlUserId = JSON.parse(atob(urlJwt.split('.')[1]))?.userId;

      if (!storedUserId) {
        return false;
      }

      return urlUserId === storedUserId;
    } catch (error) {
      return false;
    }
  }
}
