import { Injectable } from '@angular/core';
import { filter, switchMap, take } from 'rxjs';
import { AppState } from '../../store/app.state';
import { UserState, UserType } from '../../store/user.state';
import { AppService } from '../../services/app.service';
import { ModalController } from '@ionic/angular/standalone';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationModalPage } from '../../modals/authentication/authentication-modal.page';
import { ProfileState } from '../../store/profile.state';

@Injectable({ providedIn: 'root' })
export class AuthenticationPageGuard {
  constructor(
    private appState: AppState,
    private userState: UserState,
    private appService: AppService,
    private modalController: ModalController,
    private router: Router,
    private route: ActivatedRoute,
    private profileState: ProfileState,
  ) {}

  // This guard just redirect the user to the correct component (login modal on desktop, login page on mobile)
  // It also prevent access to moya users
  async canActivate(): Promise<void> {
    this.appState.appReady$
      .pipe(
        filter(appReady => appReady),
        take(1),
        switchMap(() => this.userState.user$),
        take(1),
      )

      .subscribe(async user => {
        if (user.userType === UserType.MOYA) {
          return this.router.navigate(['home']);
        }

        const jwt = this.route.snapshot.queryParams['jwt'];
        let componentProps = null;

        // If the url is the confirmation email url, we check that the jwt match the logged in jwt
        if (this.router.url.startsWith('/confirmation-email')) {
          if (await this.matchJwt(jwt)) {
            componentProps = { confirmEmailToken: jwt };
          } else {
            if (this.userState.user$.value.userType === UserType.GUEST) {
              void this.router.navigate(['home']);
            }
            return false;
          }
        }

        if (this.router.url.startsWith('/reset-password') && this.route.snapshot.queryParams['jwt'] && this.route.snapshot.queryParams['uuid']) {
          componentProps = {
            resetPasswordData: {
              jwt: this.route.snapshot.queryParams['jwt'],
              uuid: this.route.snapshot.queryParams['uuid'],
            },
          };
        }

        if (this.appService.isMobile$.value) {
          return true;
        } else {
          await this.openAuthenticationModal(componentProps || {});
          return false;
        }
      });
  }

  private async openAuthenticationModal(componentProps = {}): Promise<void> {
    void this.router.navigate(['/home']);
    const loginCreateAccountModal = await this.modalController.create({
      component: AuthenticationModalPage,
      cssClass: 'wr-modal authentication',
      componentProps,
    });

    await loginCreateAccountModal.present();

    // Force go to profile creation
    await loginCreateAccountModal.onDidDismiss().then(() => {
      if (this.userState.user$.value.userType !== UserType.GUEST && !this.profileState.currentProfile$.value) {
        void this.router.navigate([this.profileState.profiles$.value.length > 1 ? '/profile/select' : '/profile/create'], {
          state: { hideHeader: true },
        });
      }
    });
  }

  private async matchJwt(urlJwt: string, storedUserId = this.userState.user$.value.id): Promise<boolean> {
    try {
      const urlUserId = JSON.parse(atob(urlJwt.split('.')[1]))?.userId;

      if (!storedUserId) {
        return false;
      }

      return urlUserId === storedUserId;
    } catch (error) {
      return false;
    }
  }
}
