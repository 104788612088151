import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { catchError, Observable } from 'rxjs';
import { UserState, UserType } from '../store/user.state';
import { UserService } from '../services/user.service';
import { ToastService, ToastType } from '../services/toast.service';
import { ModalController } from '@ionic/angular/standalone';
import { ProfileService } from '../services/profile.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private userState: UserState,
    private userService: UserService,
    private toastService: ToastService,
    private profileService: ProfileService,
    private modalController: ModalController,
    private translateService: TranslateService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(async error => {
        // We only do this for API's that contains the booksmart token
        if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 403) && request.headers.get('authorization')) {
          const user = this.userState.user$.value;

          // In case the guest JWT has expired for any reason, we do a silent guest authentication
          if (user.userType === UserType.GUEST || !user.userType) {
            await this.userState.remove();
            await this.profileService.reset();
            await this.userState.initialize();
          } else {
            void this.toastService.present({
              message: this.translateService.instant('PWA_toast_appIssues_error_sessionExpired'),
              type: ToastType.Negative,
              displayClose: false,
            });
            void this.modalController.dismiss();
            await this.userService.logout();
          }
        }

        throw error;
      }),
    );
  }
}
